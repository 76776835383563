import './index.scss';

// import * as test from "./modules/form.js"
import { test } from './modules/form.js';
import * as alias from './modules/comparison-slider/';
import * as slideshow from './modules/slideshow/';
import * as lax from './modules/lax/lax.js';
import * as form from './modules/contact-form/index.js';
import * as ga from './modules/ga/ga.js';

document.addEventListener('scroll', function() {
	let offsetTop = document.querySelector('body').getBoundingClientRect().top;
	let scrollIcon = document.querySelector('.icon-scroll');

	if (offsetTop < -100 && offsetTop != 0) {
		scrollIcon.classList.add('hide');
	} else {
		scrollIcon.classList.remove('hide');
	}
});

document.querySelectorAll('.image').forEach(function(elem) {
	var x = undefined,
		width = undefined;

	elem.onmouseenter = function() {
		var size = elem.getBoundingClientRect();

		x = size.x;
		width = size.width;
	};

	elem.onmousemove = function(e) {
		var horizontal = (e.clientX - x) / width * 100;

		elem.style.setProperty('--x', horizontal + '%');
	};
});

window.addEventListener('load', function() {
	console.log('fully loaded');
});
