// npm install lax.js https://github.com/alexfoxy/laxxx
var lax = require('lax.js');

window.onload = function() {
	lax.setup(); // init

	const updateLax = () => {
		lax.update(window.scrollY);
		window.requestAnimationFrame(updateLax);
	};

	window.requestAnimationFrame(updateLax);
};
