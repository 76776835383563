// add cookie consent
import CC from "CookieConsent";
import "./ga.scss";

function loadGAonConsent() {
  window.ga =
    window.ga ||
    function() {
      (ga.q = ga.q || []).push(arguments);
    };
  ga.l = +new Date();
  ga("create", "UA-155212612-1", "auto");
  ga("set", "anonymizeIp", true);
  ga("send", "pageview");
  var gascript = document.createElement("script");
  gascript.async = true;
  gascript.src = "https://www.google-analytics.com/analytics.js";
  document
    .getElementsByTagName("head")[0]
    .appendChild(gascript, document.getElementsByTagName("head")[0]);
}

if (
  document.cookie.split(";").filter(function(item) {
    return item.indexOf("cookieconsent_status=allow") >= 0;
  }).length &&
  navigator.doNotTrack != 1 &&
  navigator.doNotTrack != "yes" &&
  window.doNotTrack != 1 &&
  navigator.msDoNotTrack != 1
) {
  loadGAonConsent();
}

window.addEventListener("load", function() {
  var ccPopup;

  // init function for cookie consent
  window.cookieconsent.initialise(
    {
      palette: {},
      theme: "mono",
      type: "opt-in",
      content: {
        message:
          "Wir nutzen Cookies und Google Analytics, um diese Website für Sie so interessant wie möglich zu gestalten. Sind Sie damit einverstanden? (Sie können diese Entscheidung jederzeit widerrufen)",
        deny: "Nein danke",
        allow: "Okay",
        link: "Datenschutzerklärung",
        href: "#privacy"
      },
      onStatusChange: function(status, chosenBefore) {
        var type = this.options.type;
        var didConsent = this.hasConsented();
        if (type == "opt-in" && didConsent) {
          // enable cookies
          loadGAonConsent();
          //alert('opt in');
        } else {
          //alert('opt out');
        }
        document.querySelector(".cc-window").style.display = "none";
      }
    },
    function(popup) {
      ccPopup = popup;
    }
  );

  // own revoke btn in privacy modal
  document
    .querySelector(".my-special-revoke")
    .addEventListener("click", function() {
      ccPopup.open();
    });
});
