const formSubmit = document.querySelector('#contact-submit');

// on focus event
let inputs = document.querySelectorAll('fieldset input');
for (let index = 0; index < inputs.length; index++) {
	const element = inputs[index];
	element.addEventListener('focus', function() {
		this.parentNode.querySelector('span').innerHTML = '';
	});
}
// textarea
const textarea = document.querySelector('fieldset textarea');
textarea.addEventListener('focus', function() {
	this.parentNode.querySelector('span').innerHTML = '';
});

formSubmit.addEventListener('click', function(e) {
	e.preventDefault();
	// form fields
	var lastname = document.querySelector('#lastname');
	var firstname = document.querySelector('#firstname');
	var email = document.querySelector('#email');
	var phone = document.querySelector('#phone');
	var www = document.querySelector('#www');
	var message = document.querySelector('#message');
	var honey = document.querySelector('#honey');
	var privacy = document.querySelector('#privacy');

	// reset all error msgs
	var inputFields = document.querySelectorAll('#contact fieldset span');
	for (let index = 0; index < inputFields.length; index++) {
		const element = inputFields[index];
		element.innerHTML = '';
	}
	document.querySelector('label[for="privacy"]').style.color = 'inherit';

	// check if privacy is entered
	if (honey.checked == false) {
		var xhttp = new XMLHttpRequest();
		// ajax call function
		document.querySelector('#contact').classList.add('loading');
		xhttp.open('POST', '', true);
		xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		xhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
		xhttp.onreadystatechange = function() {
			if (this.readyState == 4 && this.status == 200) {
				console.log(this.responseText);
				var response = JSON.parse(this.responseText);

				if (response.alert != null || privacy.checked == false) {
					document.querySelector('#contact').classList.remove('loading');
					if (privacy.checked == false) {
						document.querySelector('label[for="privacy"]').style.color = '#8b2939';
					}
					if (response.alert.firstname) {
						firstname.parentNode.querySelector('span').innerHTML = response.alert.firstname;
					}
					if (response.alert.lastname) {
						lastname.parentNode.querySelector('span').innerHTML = response.alert.lastname;
					}
					if (response.alert.email) {
						email.parentNode.querySelector('span').innerHTML = response.alert.email;
					}
					if (response.alert.message) {
						message.parentNode.querySelector('span').innerHTML = response.alert.message;
					}
				} else {
					// success sending contact form -> truncate all error msgs

					// close form
					document.querySelector('#contact').classList.add('closed');
					document.querySelector('.msg_success').innerHTML = response.success;
					document.querySelector('#contact').classList.remove('loading');
				}
			}
		};
		// pass data to the controller
		xhttp.send(
			'source=' +
				window.location.href +
				'&submit=' +
				formSubmit.value +
				'&lastname=' +
				lastname.value +
				'&firstname=' +
				firstname.value +
				'&email=' +
				email.value +
				'&message=' +
				message.value +
				'&phone=' +
				phone.value +
				'&www=' +
				www.value +
				'&honey=' +
				honey.value
		);
	} else {
		// not entered privacy checkbox
		alert('Bitte Seite neuladen und erneut versuchen!');
	}
});
